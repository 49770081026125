<template>
    <div>
        <record-box title="治疗压力">
            <template v-slot:top>
                <p class="data-subtitle-top">漏气</p>
            </template>
            <template v-slot:content>
                <div class="data">
                    <div class="data-column">
                        <record-label title="90%吸气压力"
                            :value="placeholderText(pressure90, 'cmH2O')"></record-label>
                        <record-label v-if="双水平模式" title="90%呼气压力"
                            :value="placeholderText(exhalePressure90, 'cmH2O')"></record-label>
                        <p class="data-subtitle-column">血氧</p>
                        <record-label title="平均血氧" :value="placeholderText(parseFloat(avgBloodOxygen), '%')"></record-label>
                        <record-label title="最小血氧" :value="placeholderText(parseFloat(minBloodOxygen), '%')"></record-label>
                    </div>
                    <div class="data-column">
                        <record-label title="平均漏气"
                            :value="placeholderText(avgAirLeakage, 'L/min')"></record-label>
                        <div v-if="双水平模式" class="data-column-space"></div>
                        <p class="data-subtitle-column">心率</p>
                        <record-label title="平均心率" :value="placeholderText(parseFloat(avgHeartRate), 'bpm')"></record-label>
                        <record-label title="最大心率" :value="placeholderText(parseFloat(maxHeartRate), 'bpm')"></record-label>
                    </div>
                </div>
            </template>
        </record-box>
        <record-box title="事件统计">
            <template v-slot:content>
                <div class="data">
                    <div class="data-column">
                        <record-label title="AHI" :value="placeholderText(ahi)"></record-label>
                        <record-label title="AI" :value="placeholderText(ai)"></record-label>
                        <record-label title="HI" :value="placeholderText(hi)"></record-label>
                    </div>
                    <div class="data-column">
                        <record-label title="CSR" :value="placeholderText(csr)"></record-label>
                        <record-label title="CSA" :value="placeholderText(csa)"></record-label>
                        <record-label title="Biot" :value="placeholderText(biot)"></record-label>
                    </div>
                </div>
            </template>
        </record-box>
    </div>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordLabel from "@c/record-frame/common/record-label.vue";
export default {
    components: {
        RecordBox,
        RecordLabel,
    },

    props: {
        recordModel: Object
    },

    computed: {
        //取值用
        ahi() {
            return this.recordModel?.ahi
        },

        csa() {
            return this.recordModel?.csa
        },

        ai() {
            return this.recordModel?.ai
        },

        csr() {
            return this.recordModel?.csr
        },

        hi() {
            return this.recordModel?.hi
        },

        biot() {
            return this.recordModel?.biot
        },

        pressure90() {
            return this.recordModel?.pressure90
        },

        exhalePressure90() {
            return this.recordModel?.exhalePressure90
        },

        cureMode() {
            return this.recordModel?.cureMode
        },

        //双水平模式还要显示呼气压力
        双水平模式() {
            return !(this.cureMode == 'APAP' || this.cureMode == 'CPAP' || this.cureMode == 'CPAP,APAP' || this.cureMode == 'APAP,CPAP') //非单水平
        },

        avgAirLeakage() {
            return this.recordModel?.avgAirLeakage
        },

        avgBloodOxygen() {
            return this.recordModel?.avgBloodOxygen
        },

        minBloodOxygen() {
            return this.recordModel?.minBloodOxygen
        },

        avgHeartRate() {
            return this.recordModel?.avgHeartRate
        },

        maxHeartRate() {
            return this.recordModel?.maxHeartRate
        },

    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        }
    }
}
</script>

<style lang="scss" scoped>
.data {
    display: flex;

    &-column {
        width: 50%;

        &-space {
            height: 40px;
        }
    }

    &-subtitle {
        &-top {
            font-size: 16px;
            font-weight: bolder;
            width: 50%;
        }

        &-column {
            font-size: 16px;
            font-weight: bolder;
            margin-bottom: 28px;

        }
    }

}
</style>