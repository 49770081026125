<template>
    <div class="pdf noPrint">
        <pdf class="pdf-content" v-for="i in numPages" :key="i" :src="src" :page="i">
        </pdf>
    </div>
</template>

<script>

import pdf from 'vue-pdf'

export default {
    components: {
        pdf
    },

    props: {
        pdfURL: String
    },

    data() {
        return {
            src: undefined,
            numPages: undefined,
        }
    },

    mounted() {
        this.src = pdf.createLoadingTask(this.pdfURL);
        this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
        });
    },

}

</script>

<style lang="scss">
@media print {
    .noPrint {
        background-color: white !important;
    }
}

.pdf {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -65px;

    &-content {
        width: 900px;
        margin-bottom: 30px;
    }
}
</style>

