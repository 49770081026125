<template>
    <div class="history-record-display">
        <div class="history-record-display-container">
            <record-header :recordModel="recordModel"></record-header>
            <record-user-info :recordModel="recordModel"></record-user-info>
            <record-editor-display title="技师评估" signatureTitle="技师签字" :content="technicianAssignment"
                :signature="technicianSignature" minHeight="244"></record-editor-display>
            <record-editor-display title="医师评估" signatureTitle="医师签字" :content="doctorAssignment"
                :signature="doctorSignature" minHeight="244"></record-editor-display>
            <div style="page-break-after: always"></div>
            <parameter v-if="isSelectedDates && paramModel" title="治疗参数" :paramModel="paramModel"></parameter>
            <estimate-data v-if="isSelectedDates" :recordModel="recordModel"></estimate-data>
            <record-compliance :recordModel="recordModel"></record-compliance>
            <div v-if="paramModel" style="page-break-after: always"></div>
            <statistics-charts v-if="isSelectedDates && chartData" :chartData="chartData"></statistics-charts>
            <estimate-chart v-if="estimateChartArray.length" :chartArray="estimateChartArray"></estimate-chart>
        </div>
    </div>
</template>

<script>
import RecordHeader from "@c/record-frame/estimate-record/estimate-record-display/estimate-record-display-header.vue";
import RecordUserInfo from "@c/record-frame/estimate-record/estimate-record-display/estimate-record-display-user-info.vue";
import EstimateData from "@c/record-frame/estimate-record/common/estimate-data.vue"
import RecordCompliance from "@c/record-frame/estimate-record/estimate-record-display/estimate-record-display-compliance.vue";
import Parameter from "@c/record-frame/estimate-record/common/estimate-record-parameter/estimate-record-parameter.vue";
import EstimateChart from "@c/record-frame/estimate-record/common/estimate-chart.vue"
import StatisticsCharts from "@c/report/charts/record-statistics-charts.vue";
import RecordEditorDisplay from "@c/record-frame/common/record-editor-display.vue";
export default {
    components: {
        RecordHeader,
        RecordUserInfo,
        EstimateData,
        RecordCompliance,
        Parameter,
        EstimateChart,
        StatisticsCharts,
        RecordEditorDisplay
    },

    props: {
        recordModel: Object
    },

    computed: {
        selectedRecordDurationModel() {
            return this.$store.state.selectedRecordDurationModel
        },

        isSelectedDates() {
            return this.selectedRecordDurationModel?.selectedDatesArray?.length
        },

        estimateChartArray() {
            const estimateChartArrayStr = this.recordModel?.adaptabilityAssessmentArr
            if (estimateChartArrayStr) {
                return JSON.parse(estimateChartArrayStr)
            } else {
                return []
            }
        },

        paramModel() {
            const paramModelStr = this.recordModel?.respiratorParameter
            if (paramModelStr) {
                return JSON.parse(paramModelStr)
            } else {
                return null
            }
        },

        chartData() {
            const chartDataStr = this.recordModel?.chartData
            if (chartDataStr) {
                return JSON.parse(chartDataStr)
            } else {
                return null
            }
        },

        doctorAssignment() {
            return this.recordModel?.physicianAssessment
        },

        technicianAssignment() {
            return this.recordModel?.technicianAssessment
        },

        doctorSignature() {
            return this.recordModel?.physicianAutograph
        },

        technicianSignature() {
            return this.recordModel?.technicianAutograph
        }
    },
}
</script>

<style lang="scss" scoped>
.history-record-display {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
        width: 900px;
        box-sizing: border-box;
        padding-bottom: 100px;

        &-configuration {
            margin-top: 18px;

            &-title {
                font-weight: bolder;
            }
        }

        &-use-time {
            margin-top: 18px;
        }
    }
}
</style>