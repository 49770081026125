export function computeBMI(height, weight, decimal = 2) {
    const heightNumber = Number(height);
    const weightNumber = Number(weight);
    if (isNaN(heightNumber) || isNaN(weightNumber) || heightNumber == 0 || weightNumber == 0) {
        return "";
    } else {
        const rawBMI = weightNumber / Math.pow(heightNumber / 100, 2);
        const bmi = rawBMI.toFixed(decimal);
        return `${bmi}`;
    }
}

export function computeSex(gender) {
    switch (gender) {
        case "男":
            return 1
        case "女":
            return 2
        default:
            return 0
    }
}

export function countDown({
    remainSeconds: remainSeconds,
    trigger: trigger,
    over: over
}) {
    let timer = setInterval(() => {
        remainSeconds--;
        trigger(remainSeconds);
        if (remainSeconds == 0) {
            clearInterval(timer);
            over();
        }
    }, 1000);
}

export function getHexColor(color) {
    var values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',')
    var a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
    return '#' +
        ('0' + r.toString(16)).slice(-2) +
        ('0' + g.toString(16)).slice(-2) +
        ('0' + b.toString(16)).slice(-2)
}

export function getType(value) {
    //通过call方法将Object.prototype.toString方法复制到value上
    let rawString = Object.prototype.toString.call(value) //`[object 类型]`
    return rawString.slice(8, -1)
}
