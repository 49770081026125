<template>
    <div class="estimate">
        <p class="estimate-chart-title">关键指标改善情况（部分）</p>
        <div class="estimate-chart-content" :style="`height: ${230 / zoom}px;`">
            <div id="estimate"
                :style="`width: ${100 / zoom}%; height: ${225 / zoom}px;zoom:${1 / zoom};transform:scale(${zoom});transform-origin:0 0 `">
            </div>
        </div>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
    props: {
        chartArray: Array,
    },

    data() {
        return {
            chart: null,
        };
    },

    computed: {
        zoom() {
            return this.$store.state.zoom
        },

        timeArray() {
            return this.chartArray?.map(item => {
                return DateTool.timestampToStr(item.time, "YYYY/MM/DD")
            })
        },

        ahiArray() {
            return this.chartArray?.map(item => {
                return parseFloat(item.ahi)
            })
        },
        odi3Array() {
            return this.chartArray?.map(item => {
                return parseFloat(item.odi3)
            })
        },
        minSPO2Array() {
            return this.chartArray?.map(item => {
                return parseFloat(item.minO2)
            })
        },

        avgSPO2Array() {
            return this.chartArray?.map(item => {
                return parseFloat(item.avgO2)
            })
        }
    },

    mounted() {
        this.chart = this.$echarts.init(document.getElementById("estimate"));
        this.drawLine();

    },

    watch: {
        chartArray() {
            this.drawLine();
        },
    },

    methods: {
        drawLine() {
            this.chart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['AHI', '氧减指数（ODI3）', '最低血氧', '平均血氧'],
                    right: 30
                },
                grid: {
                    left: '3%',
                    right: "40px",
                    bottom: '2%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    offset: 5,
                    boundaryGap: false,
                    data: this.timeArray
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'AHI',
                        type: 'line',
                        data: this.ahiArray
                    },
                    {
                        name: '氧减指数（ODI3）',
                        type: 'line',
                        data: this.odi3Array
                    },
                    {
                        name: '最低血氧',
                        type: 'line',
                        data: this.minSPO2Array
                    },
                    {
                        name: '平均血氧',
                        type: 'line',
                        data: this.avgSPO2Array
                    }
                ]
            });

            setTimeout(() => {
                window.onresize = () => {
                    this.chart.resize();
                };
            }, 200);
        },
    }
}
</script>

<style lang="scss" scoped>
.estimate {
    box-sizing: border-box;
    margin-top: 20px;

    &-chart {
        display: flex;
        flex-direction: column;

        &-title {
            color: black;
            font-size: 16px;
        }

        &-content {
            @include z-index-2;
            position: relative;
            top: -20px;
            background-color: transparent;
            align-self: center;
            width: 100%;
        }
    }
}
</style>