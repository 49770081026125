<template>
    <record-box title="依从性分析">
        <template v-slot:content>
            <div class="compliance">
                <div class="compliance-column">
                    <record-input title="总天数" width="120" titleWidth="122" unit="天" v-model="totalDay"></record-input>
                    <record-input title="已使用天数" width="120" titleWidth="122" :unit="`天${usePercent}`"
                        v-model="usedDay"></record-input>
                    <record-input title="超过4小时天数" width="120" titleWidth="122" :unit="`天${moreThan4HoursPercent}`"
                        v-model="over4HoursDay"></record-input>
                    <record-input title="平均使用时长（所有天数）" v-model="averageTotalTime"></record-input>
                    <record-input title="AHI<5天数" width="120" titleWidth="122" unit="天"
                        v-model="ahiUnder5Day"></record-input>
                </div>
                <div class="compliance-column">
                    <record-input title="总时长" titleWidth="122" v-model="totalTime"></record-input>
                    <record-input title="未使用天数" width="120" titleWidth="122" :unit="`天${notUserPercent}`"
                        v-model="unusedDay"></record-input>
                    <record-input title="小于4小时天数" width="120" titleWidth="122" :unit="`天${lessThan4HoursPercent}`"
                        v-model="under4HoursDay"></record-input>
                    <record-input title="平均使用时长（已使用天数）" v-model="averageUsedTime"></record-input>
                </div>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordInput from "@c/record-frame/common/record-input.vue";

export default {
    components: {
        RecordBox,
        RecordInput
    },

    props: {
        recordModel: Object
    },

    data() {
        return {
            totalDay: undefined,
            totalTime: undefined,
            usedDay: undefined,
            unusedDay: undefined,
            over4HoursDay: undefined,
            under4HoursDay: undefined,
            averageTotalTime: undefined,
            averageUsedTime: undefined,
            ahiUnder5Day: undefined,
        }
    },

    computed: {
        usePercent() {
            const totalDay = parseFloat(this.totalDay)
            const usedDay = parseFloat(this.usedDay)
            if (!isNaN(totalDay) && !isNaN(usedDay)) {
                const rawPercent = usedDay / totalDay * 100
                return `（${rawPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        notUserPercent() {
            const totalDay = parseFloat(this.totalDay)
            const unusedDay = parseFloat(this.unusedDay)
            if (!isNaN(totalDay) && !isNaN(unusedDay)) {
                const rawPercent = unusedDay / totalDay * 100
                return `（${rawPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        moreThan4HoursPercent() {
            const totalDay = parseFloat(this.totalDay)
            const moreThan4HoursDay = parseFloat(this.over4HoursDay)
            if (!isNaN(totalDay) && !isNaN(moreThan4HoursDay)) {
                const rawPercent = moreThan4HoursDay / totalDay * 100
                return `（${rawPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        lessThan4HoursPercent() {
            const totalDay = parseFloat(this.totalDay)
            const lessThan4HoursDay = parseFloat(this.under4HoursDay)
            if (!isNaN(totalDay) && !isNaN(lessThan4HoursDay)) {
                const rawPercent = lessThan4HoursDay / totalDay * 100
                return `（${rawPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },
    },

    watch: {
        recordModel(value) {
            if (value.processedBy == 'baseline') { return }
            this.totalDay = value?.totalDay?.toFixed(0) ?? null //否则为undefined，axios请求时会自动忽略
            this.totalTime = value?.totalDuration ?? null
            this.usedDay = value?.useDay?.toFixed(0) ?? null
            this.unusedDay = value?.notUseDay?.toFixed(0) ?? null
            this.over4HoursDay = value?.moreThan4HoursDay?.toFixed(0) ?? null
            this.under4HoursDay = value?.lessThan4HoursDay?.toFixed(0) ?? null
            this.averageTotalTime = value?.allAvgUseTime ?? null
            this.averageUsedTime = value?.avgUseTime ?? null
            this.ahiUnder5Day = value?.ahi5Day?.toFixed(0) ?? null
        }
    }
}
</script>

<style lang="scss" scoped>
.compliance {
    display: flex;

    &-column {
        width: 50%;
    }

}
</style>