<template>
    <div class="estimate-record-display-header">
        <div class="estimate-record-display-header-title">{{title}}</div>
        <div class="estimate-record-display-header-content">
            <p>设备序列号：{{ sn }}</p>
            <p class="estimate-record-display-header-content-time">统计时间：{{ statisticsDurationStr }}</p>
        </div>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
    props: {
        recordModel: Object
    },

    computed: {
        title(){
            return placeholderText(this.recordModel?.title)
        },

        sn() {
            return placeholderText(this.recordModel?.sn)
        },

        statisticsDurationStr() {
            const selectedDurationList = this.recordModel?.recordDateList
            if (selectedDurationList && selectedDurationList.length != 0) {
                //firstDateStr
                const firstDateStr = `${selectedDurationList[0]} 12:00:00`
                //lastNextDateStr
                const lastDate = new Date(selectedDurationList[selectedDurationList.length - 1])
                const lastNextDayTimestamp = lastDate.setDate(lastDate.getDate() + 1);
                const lastNextDateStr = `${DateTool.milliTimestampToStr(lastNextDayTimestamp, "YYYY-MM-DD")} 12:00:00`;
                return `${firstDateStr} ~ ${lastNextDateStr}`
            } else {
                return "未选择日期"
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.estimate-record-display-header {
    background-color: #F7F7F7;
    height: 156px;
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    box-sizing: border-box;
    padding: 32px 25px 0px 27px;

    &-title{
        color: black;
        font-size: 30px;
        font-weight: bolder;
        height: 42px;
    }

    &-content {
        color: rgba($color: #000000, $alpha: 0.44);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 31px;

        &-time {
            width: 385px;
        }
    }
}
</style>