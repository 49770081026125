<template>
    <record-box title="患者资料">
        <template v-slot:content>
            <div class="user-info">
                <div class="user-info-column">
                    <record-label title="姓名" :value="name"></record-label>
                    <record-label title="性别" :value="gender"></record-label>
                    <record-label title="年龄" :value="age"></record-label>
                </div>
                <div class="user-info-column">
                    <record-label title="身高" :value="height"></record-label>
                    <record-label title="体重" :value="weight"></record-label>
                    <record-label title="BMI" :value="recordModel?.bmi"></record-label>
                </div>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordLabel from "@c/record-frame/common/record-label.vue";
import { findKey } from "@js/object-tool.js";
export default {
    components: {
        RecordBox,
        RecordLabel,
    },

    props: {
        recordModel: Object
    },

    computed: {
        name() {
            return placeholderText(this.recordModel?.name)
        },

        gender() {
            return placeholderText(findKey(USER_GENDER, this.recordModel?.sex))
        },

        age() {
            return placeholderText(this.recordModel?.age)
        },

        height() {
            return placeholderText(this.recordModel?.height, "cm")
        },

        weight() {
            return placeholderText(this.recordModel?.weight, "kg")
        },

        bmi(){
            return placeholderText(this.recordModel?.bmi)
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info {
    display: flex;

    &-column {
        width: 50%;
    }

}
</style>