<template>
    <div class="history-record-edit">
        <div class="history-record-edit-container">
            <record-header ref="headRef" :recordModel="recordModel"></record-header>
            <user-info ref="userInfoRef" :recordModel="recordModel"></user-info>
            <baseline ref="baselineRef" :recordModel="recordModel" @onBaselineChanged="onBaselineChanged"></baseline>
            <record-editor ref="technicianRef" title="技师评估" signatureTitle="技师签字" minHeight="244"
                :initContent="recordModel?.technicianAssessment"
                :initSignature="recordModel?.technicianAutograph"></record-editor>
            <record-editor ref="doctorRef" title="医师评估" signatureTitle="医师签字" minHeight="244"
                :initContent="recordModel?.physicianAssessment"
                :initSignature="recordModel?.physicianAutograph"></record-editor>
            <div style="page-break-after: always"></div>
            <parameter v-if="isSelectedDates && paramModel" ref="parameterRef" title="治疗参数" :paramModel="paramModel">
            </parameter>
            <estimate-data v-show="isSelectedDates" ref="dataRef" :recordModel="recordModel"></estimate-data>
            <compliance ref="complianceRef" :recordModel="recordModel"></compliance>
            <div style="page-break-after: always"></div>
            <statistics-charts v-if="isSelectedDates && chartData" :chartData="chartData"></statistics-charts>
            <estimate-chart v-if="estimateChartArray.length" :chartArray="estimateChartArray"></estimate-chart>

        </div>
    </div>
</template>

<script>
import RecordHeader from "@c/record-frame/estimate-record/estimate-record-edit/estimate-record-edit-header.vue";
import Baseline from "@c/record-frame/estimate-record/estimate-record-edit/estimate-record-edit-baseline.vue";
import UserInfo from "@c/record-frame/estimate-record/estimate-record-edit/estimate-record-edit-user-info.vue";
import Compliance from "@c/record-frame/estimate-record/estimate-record-edit/estimate-record-edit-compliance.vue";
import Parameter from "@c/record-frame/estimate-record/common/estimate-record-parameter/estimate-record-parameter.vue";
import EstimateData from "@c/record-frame/estimate-record/common/estimate-data.vue"
import StatisticsCharts from "@c/report/charts/record-statistics-charts.vue";
import EstimateChart from "@c/record-frame/estimate-record/common/estimate-chart.vue"
import RecordEditor from "@c/record-frame/common/record-editor.vue";

export default {
    components: {
        RecordHeader,
        Baseline,
        UserInfo,
        Compliance,
        Parameter,
        EstimateData,
        StatisticsCharts,
        EstimateChart,
        RecordEditor
    },

    props: {
        initRecordModel: Object,
    },

    data() {
        return {
            //ref
            headRef: undefined,
            baselineRef: undefined,
            userInfoRef: undefined,
            dataRef: undefined,
            complianceRef: undefined,
            technicianRef: undefined,
            doctorRef: undefined,
            //recordModel
            recordModel: undefined,
        }
    },

    computed: {
        selectedRecordDurationModel() {
            return this.$store.state.selectedRecordDurationModel
        },

        isSelectedDates() {
            return this.selectedRecordDurationModel?.selectedDatesArray?.length
        },


        estimateChartArray() {
            const estimateChartArrayStr = this.recordModel?.adaptabilityAssessmentArr
            if (estimateChartArrayStr) {
                return JSON.parse(estimateChartArrayStr)
            } else {
                return []
            }
        },

        paramModel() {
            const paramModelStr = this.recordModel?.respiratorParameter
            if (paramModelStr) {
                return JSON.parse(paramModelStr)
            } else {
                return null
            }
        },

        chartData() {
            const chartDataStr = this.recordModel?.chartData
            if (chartDataStr) {
                return JSON.parse(chartDataStr)
            } else {
                return null
            }
        }
    },

    mounted() {
        //ref
        this.headRef = this.$refs.headRef
        this.baselineRef = this.$refs.baselineRef
        this.userInfoRef = this.$refs.userInfoRef
        this.dataRef = this.$refs.dataRef
        this.complianceRef = this.$refs.complianceRef
        this.technicianRef = this.$refs.technicianRef
        this.doctorRef = this.$refs.doctorRef
        //recordModel
        this.recordModel = this.initRecordModel
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy()
        }
    },

    watch: {
        initRecordModel(value) {
            this.recordModel = value
        },

        recordModel(value) {
            //dataRef不是一开始就出现的，但编辑患者记录需要它携带的数据，因此recordModel有值的话必须可以取到
            this.dataRef = this.$refs.dataRef
        },

    },

    methods: {
        onBaselineChanged() {
            this.$emit("onBaselineChanged")
        }
    }

}
</script>

<style lang="scss" scoped>
//
.history-record-edit {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
        width: 900px;
        box-sizing: border-box;
        padding-bottom: 34px;

        &-configuration {
            margin-top: 18px;

            &-title {
                font-weight: bolder;
            }
        }
    }

}
</style>
