<template>
    <div class="record-label">
        <p class="record-label-title" :style="{ width: `${titleWidth}px` }">{{ title }}：</p>
        <p class="record-label-value" >{{ value }}</p>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        elInput: Input,
    },

    props: {
        title: String,

        value: String,

        titleWidth: String,

        width: {
            type: String,
            default: "180"
        }
    }

}
</script>

<style lang="scss" scoped>
.record-label {
    color: black;
    // height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-title {
        font-size: 16px;
        margin-right: 12px;
    }

    &-value{
        font-size: 16px;

    }
}
</style>