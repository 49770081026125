<template>
    <record-box title="患者资料">
        <template v-slot:content>
            <div class="user-info">
                <div class="user-info-column">
                    <record-input title="姓名" v-model="userName"></record-input>
                    <record-selector class="user-info-column-gender" title="性别" :dataArray="genderArray"
                        v-model="gender">></record-selector>
                    <record-input title="年龄" v-model="age"></record-input>
                </div>
                <div class="user-info-column">
                    <record-input title="身高" v-model="height" width="138" unit="cm"></record-input>
                    <record-input title="体重" v-model="weight" width="138" unit="kg"></record-input>
                    <record-label title="BMI" :value="bmi"></record-label>
                </div>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordInput from "@c/record-frame/common/record-input.vue";
import RecordLabel from "@c/record-frame/common/record-label.vue";
import RecordSelector from "@c/record-frame/common/record-selector.vue";
import { computeBMI } from "@js/common-tool.js";
export default {
    components: {
        RecordBox,
        RecordInput,
        RecordLabel,
        RecordSelector
    },

    props: {
        patientID: Number,
        recordModel: Object
    },

    data() {
        return {
            genderArray: [{
                label: "男",
                value: 1
            }, {
                label: "女",
                value: 2
            }],
            userName: undefined,
            height: undefined,
            gender: undefined,
            weight: undefined,
            age: undefined,
            bmi: undefined
        }
    },

    watch: {
        recordModel(value) {
            if (value.processedBy) { return }
            this.userName = value.name
            this.height = value.height
            this.gender = value.sex
            this.weight = value.weight
            this.age = value.age
        },

        height() {
            this.bmi = computeBMI(this.height, this.weight)
        },

        weight() {
            this.bmi = computeBMI(this.height, this.weight)
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info {
    display: flex;

    &-column {
        width: 50%;

        &-gender{
            margin-bottom: 20px;
        }
    }

}
</style>