<template>
    <div class="record-box">
        <div class="record-box-top">
            <p class="record-box-top-title">{{ title }}</p>
            <slot name="top"></slot>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }

}
</script>

<style lang="scss" scoped>
.record-box {
    width: 100%;
    border: 1px solid rgba($color: black, $alpha: 0.14);
    box-sizing: border-box;
    padding: 24px 0px 0px 25px;
    margin-top: 18px;

    &-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;

        &-title {
            color: black;
            font-size: 16px;
            font-weight: bolder;
        }
    }


}
</style>