<template>
    <record-box title="依从性分析">
        <template v-slot:content>
            <div class="compliance">
                <div class="compliance-column">
                    <record-label title="总天数" :value="totalDay"></record-label>
                    <record-label title="已使用天数" :value="`${useDay}${usePercent}`"></record-label>
                    <record-label title="超过4小时天数" :value="`${moreThan4HoursDay}${moreThan4HoursPercent}`"></record-label>
                    <record-label title="平均使用时长（所有天数）" :value="allAvgUseTime"></record-label>
                    <record-label title="AHI<5天数" :value="ahi5Day"></record-label>
                </div>
                <div class="compliance-column">
                    <record-label title="总时长" :value="totalDuration"></record-label>
                    <record-label title="未使用天数" :value="`${notUseDay}${notUserPercent}`"></record-label>
                    <record-label title="小于4小时天数" :value="`${lessThan4HoursDay}${lessThan4HoursPercent}`"></record-label>
                    <record-label title="平均使用时长（已使用天数）" :value="avgUseTime"></record-label>
                </div>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordLabel from "@c/record-frame/common/record-label.vue";
export default {
    components: {
        RecordBox,
        RecordLabel,
    },

    props: {
        recordModel: Object
    },

    computed: {
        totalDay() {
            return placeholderText(this.recordModel?.totalDay?.toFixed(0), "天")
        },

        totalDuration() {
            return placeholderText(this.recordModel?.totalDuration)
        },

        useDay() {
            return placeholderText(this.recordModel?.useDay?.toFixed(0), "天")

        },

        notUseDay() {
            return placeholderText(this.recordModel?.notUseDay?.toFixed(0), "天")
        },

        usePercent() {
            const rawUsePercent = this.recordModel?.useDay / this.recordModel?.totalDay * 100
            if (rawUsePercent) {
                return `（${rawUsePercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        notUserPercent() {
            const rawUsePercent = this.recordModel?.useDay / this.recordModel?.totalDay * 100
            if (rawUsePercent) {
                return `（${100 - rawUsePercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },



        moreThan4HoursDay() {
            return placeholderText(this.recordModel?.moreThan4HoursDay?.toFixed(0), "天")
        },

        lessThan4HoursDay() {
            return placeholderText(this.recordModel?.lessThan4HoursDay?.toFixed(0), "天")
        },

        moreThan4HoursPercent() {
            const rawMoreThan4HoursPercent = this.recordModel?.moreThan4HoursDay / this.recordModel?.totalDay * 100
            if (rawMoreThan4HoursPercent) {
                return `（${rawMoreThan4HoursPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        lessThan4HoursPercent() {
            const rawMoreThan4HoursPercent = this.recordModel?.moreThan4HoursDay / this.recordModel?.totalDay * 100
            if (rawMoreThan4HoursPercent) {
                return `（${100 - rawMoreThan4HoursPercent.toFixed(0)}%）`
            } else {
                return ""
            }
        },

        allAvgUseTime() {
            return placeholderText(this.recordModel?.allAvgUseTime)
        },

        avgUseTime() {
            return placeholderText(this.recordModel?.avgUseTime)
        },

        ahi5Day() {
            return placeholderText(this.recordModel?.ahi5Day?.toFixed(0), "天")
        }

    }
}
</script>

<style lang="scss" scoped>
.compliance {
    display: flex;

    &-column {
        width: 50%;
    }

}
</style>