<template>
    <div class="estimate-record-edit-header">
        <div class="estimate-record-edit-header-top">
            <record-input class="estimate-record-edit-header-top-input" title="标题" v-model="title"
                width="376"></record-input>
        </div>
        <div class="estimate-record-edit-header-content">
            <p>设备序列号：{{ sn }}</p>
            <p class="estimate-record-edit-header-content-time">统计时间：{{ statisticsDurationStr }}</p>
        </div>
    </div>
</template>

<script>
import { Input } from "element-ui";
import RecordInput from "@c/record-frame/common/record-input.vue";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        elInput: Input,
        RecordInput
    },

    props: {
        recordModel: Object
    },

    data() {
        return {
            title: undefined
        }
    },

    computed: {
        sn() {
            return this.recordModel?.sn
        },

        selectedRecordDurationModel() {
            return this.$store.state.selectedRecordDurationModel
        },

        statisticsDurationStr() {
            const selectedDurationList = this.selectedRecordDurationModel?.selectedDatesArray
            if (selectedDurationList && selectedDurationList.length != 0) {
                //firstDateStr
                const firstDateStr = `${selectedDurationList[0]} 12:00:00`
                //lastNextDateStr
                const lastDate = new Date(selectedDurationList[selectedDurationList.length - 1])
                const lastNextDayTimestamp = lastDate.setDate(lastDate.getDate() + 1);
                const lastNextDateStr = `${DateTool.milliTimestampToStr(lastNextDayTimestamp, "YYYY-MM-DD")} 12:00:00`;
                return `${firstDateStr} ~ ${lastNextDateStr}`
            } else {
                return "未选择日期"
            }
        }
    },

    watch: {
        recordModel(value) {
            if (value.processedBy) { return }
            this.title = value.title
        }
    }

}
</script>

<style lang="scss" scoped>
.estimate-record-edit-header {
    background-color: #F7F7F7;
    height: 156px;
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    box-sizing: border-box;
    padding: 30px 25px 0px 22px;

    &-content {
        color: rgba($color: #000000, $alpha: 0.44);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        &-time {
            width: 385px;
        }
    }
}
</style>