<template>
    <div class="statistics-charts">
        <statistics-chart title="使用时长（小时）" :max="24" :dayArray="dayArray"
            :chartDataArray="useHourArray"></statistics-chart>
        <statistics-chart title="90%压力趋势（cmH2O）" :dayArray="dayArray"
            :chartDataArray="p90Array"></statistics-chart>
        <statistics-chart title="CSA/CSR" :max="5" :dayArray="dayArray"
            :chartDataArray="csaDataArray"></statistics-chart>
        <statistics-chart title="漏气趋势" :max="chartData.maxLeakage" :dayArray="dayArray"
            :chartDataArray="leakDataArray"></statistics-chart>
    </div>
</template>

<script>
import StatisticsChart from "@c/report/charts/chart/statistics-chart/statistics-chart.vue";
export default {
    components: {
        StatisticsChart,
    },

    props: {
        chartData: Object,

        isLite: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dayArray: [],

            useHourArray: [],

            p90Array: [],

            csaDataArray: [],

            leakDataArray: []
        };
    },

    mounted() {
        this.setupData()
    },

    watch: {
        chartData() {
            this.setupData()
        },
    },

    methods: {
        setupData() {
            this.dayArray = this.chartData.dayArray
            this.useHourArray = [
                {
                    name: "大于4小时",
                    type: "bar",
                    color: "#58C93D",
                    stack: null,
                    dataArray: this.chartData.useHourAbove4Array,
                },
                {
                    name: "小于4小时",
                    type: "bar",
                    color: "#FF4E4E",
                    stack: null,
                    dataArray: this.chartData.useHourUnder4Array,
                },
            ]
            this.p90Array = [
                {
                    name: "90%压力趋势",
                    type: "line",
                    color: "#000000",
                    stack: null,
                    dataArray: this.chartData.pressure90Array,
                },
                {
                    name: "最大压力设定",
                    type: "line",
                    color: "#FFD188",
                    stack: null,
                    dataArray: this.chartData.pressureMaxArray,
                },
                {
                    name: "最小压力设定",
                    type: "line",
                    color: "#FC8CEF",
                    stack: null,
                    dataArray: this.chartData.pressureMinArray,
                },
            ]
            this.csaDataArray = [
                {
                    name: "CSA",
                    type: "bar",
                    color: "#F8B3AE",
                    stack: "csa",
                    dataArray: this.chartData.csaArray,
                },
                {
                    name: "CSR",
                    type: "bar",
                    color: "#F8E1AE",
                    stack: "csa",
                    dataArray: this.chartData.csrArray,
                },
            ]
            this.leakDataArray = [
                {
                    name: "大于5（L/min)",
                    type: "bar",
                    color: "#D9732F",
                    stack: null,
                    dataArray: this.chartData.leakageAbove5Array,
                },
                {
                    name: "小于5（L/min)",
                    type: "bar",
                    color: "#F9CD14",
                    stack: null,
                    dataArray: this.chartData.leakageUnder5Array,
                },
            ];

        }
    }
};
</script>

<style lang="scss" scoped>
.statistics-charts {
    margin-top: 20px;

    /deep/.statistics-chart {
        border: 1px solid white;
        padding: 0px;
    }
}
</style>