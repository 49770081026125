<template>
    <record-box :title="title">
        <template v-slot:content>
            <div class="data">
                <record-label class="data-item" v-for="(item, index) of dataArray" :key="index" :title="item.title"
                    :value="item.info"></record-label>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordLabel from "@c/record-frame/common/record-label.vue";
import { ParameterComposeTool } from "@js/parameter-compose-tool.js";
export default {
    components: {
        RecordBox,
        RecordLabel
    },

    props: {
        title: String,
        paramModel: Object
    },

    data() {
        return {
            dataArray: []
        }
    },

    async created() {
        if (!this.paramModel) { return [] }
        const parameterComposeTool = new ParameterComposeTool(this.paramModel)
        this.dataArray = await parameterComposeTool.composeHolographyParamArray(this.paramModel)
    },

    watch: {
        async paramModel(value) {
            if (!value) { return [] }
            const parameterComposeTool = new ParameterComposeTool(value)
            this.dataArray = await parameterComposeTool.composeHolographyParamArray(value)
        }
    }

}
</script>

<style lang="scss" scoped>
.data {
    display: flex;
    flex-wrap: wrap;

    &-item {
        width: 50%;
    }

}
</style>