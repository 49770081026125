<template>
    <div class="record-editor">
        <p v-if="title" class="record-editor-title">{{ title }}：</p>
        <div class="record-editor-editor" :style="{ minHeight: `${minHeight}px` }">
            <div :id="`${title}toobar`"></div>
            <div :id="`${title}editor`"></div>
        </div>
        <div class="record-editor-bottom">
            <record-input :title="signatureTitle" v-model="signature"></record-input>
        </div>
    </div>
</template>

<script>
import RecordInput from "@c/record-frame/common/record-input.vue";
import wangEditor from "wangeditor";
export default {
    components: {
        RecordInput
    },

    props: {
        title: String,
        signatureTitle: String,
        minHeight: String,
        initContent: String,
        initSignature: String
    },

    data() {
        return {
            editor: undefined,
            content: "",
            signature: "",
        }
    },

    mounted() {
        //editor
        const editor = new wangEditor(`#${this.title}toobar`, `#${this.title}editor`);
        editor.create();
        editor.config.onchange = (newHtml) => {
            this.content = newHtml;
        };
        this.editor = editor;
        //data
        this.content = this.initContent
        this.editor.txt.html(this.content)
        this.signature = this.initSignature
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy()
        }
    },

    watch: {
        initContent(value) {
            this.content = value
            this.editor.txt.html(this.content)
        },

        initSignature(value) {
            this.signature = value
        },
    },
}
</script>

<style lang="scss" scoped>
.record-editor {

    &-title {
        color: black;
        font-size: 16px;
        height: 58px;
        display: flex;
        align-items: center;
    }

    &-editor {
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #DCDCDC;

        /deep/.w-e-toolbar {
            z-index: 999 !important;
        }

        /deep/.w-e-text-container {
            z-index: 998 !important;
        }
    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 18px;
    }

}
</style>
