<template>
    <record-box class="baseline no-print" title="基线数据">
        <template v-slot:top>
            <div class="baseline-top">
                <div class="baseline-top-info">
                    <img class="baseline-top-info-icon" src="@imgs/record-info.png">
                    <p class="baseline-top-info-content">后台记录数据，填写完后不会在发布/打印/下载中显示</p>
                </div>
                <div class="baseline-top-date-picker">
                    <p class="baseline-top-date-picker-key">选择基线数据开始日期：</p>
                    <el-date-picker v-model="startDate" type="date" :append-to-body="false" placeholder="选择日期"
                        @change="onChange">
                    </el-date-picker>
                </div>

            </div>
        </template>
        <template v-slot:content>
            <div class="baseline-content">
                <div class="baseline-content-column">
                    <record-input title="AHI" titleWidth="80" v-model="ahi" unit="次/小时" @onChange="onChange"></record-input>
                    <record-input title="AI" titleWidth="80" v-model="ai" unit="次/小时" @onChange="onChange"></record-input>
                    <record-input title="最低血氧" titleWidth="80" v-model="minSPO2" unit="%" @onChange="onChange"></record-input>
                </div>
                <div class="baseline-content-column right">
                    <record-input title="ODI3" titleWidth="168" v-model="odi3" unit="次/小时" @onChange="onChange"></record-input>
                    <record-input title="HI" titleWidth="168" v-model="hi" unit="次/小时" @onChange="onChange"></record-input>
                    <record-input title="平均血氧" titleWidth="168" v-model="avgSPO2" unit="%" @onChange="onChange"></record-input>
                </div>
            </div>
        </template>
    </record-box>
</template>

<script>
import RecordBox from "@c/record-frame/common/record-box.vue";
import RecordInput from "@c/record-frame/common/record-input.vue";
import { DatePicker } from "element-ui";
export default {
    components: {
        RecordBox,
        RecordInput,
        elDatePicker: DatePicker
    },

    props: {
        recordModel: Object
    },

    data() {
        return {
            startDate: undefined,
            ahi: undefined,
            ai:undefined,
            odi3: undefined,
            minSPO2: undefined,
            hi:undefined,
            avgSPO2: undefined
        }
    },

    watch: {
        recordModel(value) {
            if (value.processedBy) { return }
            //startDate
            const startDate = value?.baselineDataStartTime
            if (startDate) {
                this.startDate = new Date(startDate)
            }
            this.ahi = value?.baseAhi
            this.ai = value?.baseAi
            this.hi = value?.baseHi
            this.odi3 = value?.baseOdi3
            this.minSPO2 = value?.baseMinBloodOxygen
            this.avgSPO2 = value?.baseAvgBloodOxygen
        }

    },

    computed: {
        isValid() {
            const isDateEmpty = !this.startDate
            const isDataEmpty = !this.ahi && !this.odi3 && !this.ai && !this.hi && !this.minSPO2 && !this.avgSPO2
            return isDateEmpty == isDataEmpty //全是或全否
        }

    },

    methods: {
        onChange() {
            this.$emit("onBaselineChanged")
        }
    }

}
</script>

<style lang="scss" scoped>
@media print {
    .no-print {
        display: none;
    }
}

.baseline {

    &-top {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding-right: 80px;

        &-info {
            display: flex;

            &-icon {
                width: 14px;
                height: 14px;
                margin-left: 12px;
            }

            &-content {
                color: rgba($color: #9C9C9C, $alpha: 0.9);
                font-size: 12px;
                margin-left: 4px;
            }
        }

        &-date-picker {
            display: flex;
            align-items: center;

            &-key {
                color: black;
                font-size: 16px;
                margin-right: 8px;
            }

            /deep/.el-date-editor.el-input,
            .el-date-editor.el-input__inner {
                width: 178px !important;
            }
        }


    }

    &-content {
        display: flex;

        &-column {
            width: 50%;
        }

        /deep/.record-input-key {
            display: flex;
            justify-content: flex-end;
        }

    }
}
</style>