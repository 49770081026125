<template>
    <div class="record-input">
        <p class="record-input-key" :style="{ width: `${titleWidth}px` }">{{ title }}：</p>
        <el-input class="record-input-input" :style="{ width: `${width}px` }" v-model="content" placeholder="请输入"
            @change="onChange"></el-input>
        <p v-if="unit" class="record-input-unit">{{ unit }}</p>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        elInput: Input,
    },

    model: {
        prop: "initContent",
        event: "onChanged"
    },

    props: {
        title: String,

        initContent: String,

        unit: String,

        titleWidth: String,

        width: {
            type: String,
            default: "180"
        }
    },

    data() {
        return {
            content: ""
        }
    },

    mounted() {
        this.content = this.initContent
    },

    watch: {
        initContent(value) {
            this.content = value
        },

        content(value) {
            this.$emit("onChanged", value);
        },
    },

    methods: {
        onChange() {
            this.$emit("onChange")
        }
    }

}
</script>

<style lang="scss" scoped>
.record-input {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-key {
        font-size: 16px;
        margin-right: 8px;
    }

    &-unit {
        margin-left: 8px;
    }
}
</style>