<template>
    <div class="record-editor-display">
        <div class="record-editor-display-container">
            <p v-if="title" class="record-editor-display-container-title">{{ title }}：</p>
            <div class="record-editor-display-container-editor" :style="{ minHeight: `${minHeight}px` }">
                <div :id="`${title}toobar`"></div>
                <div :id="`${title}editor`"></div>
            </div>
            <p class="record-editor-display-container-signature">{{ `${signatureTitle}：${signature}` }}</p>
        </div>

    </div>
</template>

<script>
import { Input } from "element-ui";
import wangEditor from "wangeditor";
export default {
    components: {
        elInput: Input,
    },

    props: {
        title: String,
        signatureTitle: String,
        minHeight: String,
        content: String,
        signature: String,
    },

    data() {
        return {
            editor: undefined,
        }
    },

    mounted() {
        //editor
        const editor = new wangEditor(`#${this.title}toobar`, `#${this.title}editor`);
        editor.config.menus = []; //去掉编辑功能
        editor.create();
        editor.disable()
        this.editor = editor;
        //content
        this.editor.txt.html(this.content);
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy()
        }
    },

    watch: {
        content(value) {
            this.editor.txt.html(value);

        }
    }
}
</script>

<style lang="scss" scoped>
.record-editor-display {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
        width: 900px;

        &-title {
            font-size: 16px;
            height: 56px;
            display: flex;
            align-items: center;
        }

        &-signature {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 18px;
        }

        &-editor {
            background: #FFFFFF;
            border-radius: 3px;
            border: 1px solid #DCDCDC;

            /deep/.w-e-toolbar {
                z-index: 999 !important;
            }

            /deep/.w-e-text-container {
                z-index: 999 !important;
            }
        }
    }

}
</style>


